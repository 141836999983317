import React, { useEffect, useState } from "react";
import starwhite from "../res/ic_sharp-star (1).svg";
import starred from "../res/ic_sharp-star.svg";
import "../pagies/home.css";
const Stars = ({ rate }) => {
  const [raten, setRaten] = useState(0);
  const rateds = () => {
   if(raten<rate){
    setRaten(raten + 1)
    return;
   }
   else{
    alert("done")
   }
  };
  useEffect(() => {
    
    setTimeout(() => {
        rateds()
    }, 1000);
  }, []);

  return (
    <div className="stars">
         
      <img
        src={rate >= 1 ? starred : starwhite}
        alt=""
        className="iconStar"
        title={`${rate} stars`}
      />
      <img
        src={rate >= 2 ? starred : starwhite}
        alt=""
        className="iconStar"
        title={`${rate} stars`}
      />
      <img
        src={rate >= 3 ? starred : starwhite}
        alt=""
        className="iconStar"
        title={`${rate} stars`}
      />
      <img
        src={rate >= 4 ? starred : starwhite}
        alt=""
        className="iconStar"
        title={`${rate} stars`}
      />
      <img
        src={rate >= 5 ? starred : starwhite}
        alt=""
        className="iconStar"
        title={`${rate} stars`}
      />
    </div>
  );
};

export default Stars;
