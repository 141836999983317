import React from "react";
import "../pagies/home.css";
import { Link } from "react-router-dom";
import logo from '../res/mdi_house.svg'
const Service = ({header, icon, sub1, sub2, to}) => {
  return (
    <>
      <Link to={`/${to}`} className="sLink">
        <h1 className="sHeader">{header}</h1>
        <img src={icon} alt="" className="icon" />
        <small className="ow">
          {sub1} <br /> {sub2}
        </small>
      </Link>
    </>
  );
};

export default Service;
