import React from "react";
import "../sass/crp.css";
import crp2 from "../res/img/safe.png";
import crp1 from "../res/img/crpman.png";
import crp3 from "../res/img/pussle.png";
import caro1 from "../res/img/devoncrp.jfif";
import Footer from "../components/Footer";
import Carousel from "../components/Carousel";

import img1 from "../res/img/imgasset/love 1.svg";
import img2 from "../res/img/imgasset/Carousels 4 1.svg";
import img3 from "../res/img/imgasset/Carousels 5 1.svg";
const Cryptotrading = () => {
  const items = [img1, img2, img3];
  return (
    <>
      <section>
        <section className="crobody">
          <h2 className="header-sub">
            Crypto Trading <br /> with DEVON
          </h2>
          <div className="carosection">
            <Carousel items={items} />
          </div>
          <div className="dodocontain">
            <p className="saydodo">
              I’m a professional crypto trader, who have been in this business
              of cryptocurrency industry for 6 years And my areas of
              specialities are trading futures, shilling for new crypto project
              and also teaching newbie the way of trading such as technical
              analysis, trading strategies, or risk management.
            </p>

            <img src={crp1} alt="" className="dodimg" />
          </div>
          <div className="dodocontain do2">
            <img src={crp2} alt="" className="dodimg" />

            <p className="saydodo">
              <strong>MY GOAL is to</strong> <br />
              help clients navigate the complex world of cryptocurrency trading
              and achieve their financial goals via sound trading and investment
              strategies.
            </p>
          </div>
          <div className="dodocontain">
            <p className="saydodo">
              I am always looking for new avenues to connect with like-minded
              individuals and expand my network. Please feel free to reach out
              to me if you have any questions, want to shill your new project
              out to the world, or would like to deliberate on potential
              collaboration.
            </p>
            <img src={crp3} alt="" className="dodimg" />
          </div>

          <div className="expe">
            <div className="headerex">FOUNDERS’ REVIEWS</div>
            <div className="expes">
              <h2>Mark</h2> “If you can show me what you can do I’ll be happy to
              work with you until launch.”
            </div>
            <div className="expes">
              <h2>Anon </h2>“You’re the most based owner I know bro. We’ve been
              sitting for this mcap for almost a week and yet you still do your
              best. I hope all devs and owners on BSC project are just like
              you.”
            </div>
            <div className="expes">
              <h2>Ajmal</h2> “Your service has done a great job on my project.”
            </div>
            <div className="expes visnone"></div>
          </div>
        </section>
        <Footer pref='' nxt='forex' />
      </section>
    </>
  );
};

export default Cryptotrading;
