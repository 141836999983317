import React, { useState } from "react";
import "../sass/CSS/nft.css";
import invalid from "../res/img/imgasset/invalid sign.svg";
import instagram from "../res/img/imgasset/instagram.svg";
import x from "../res/img/imgasset/twitter.svg";
import mailicon from "../res/img/imgasset/mail.svg";
import arrowleft from "../res/img/imgasset/arro backward.svg";
import arrowright from "../res/img/imgasset/arrow forward.svg";
import { Link } from "react-router-dom";

const Footer = ({ pref, nxt }) => {
  const [useremail, setUseremail] = useState();
  const [stateOfMail, setStateOfMail] = useState();
  const [dirty, setDirty] = useState(false);

  const emailregex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const testmail = (email) => emailregex.test(email);

  const keepTabs = () => {
    if (testmail(useremail)) {
      setStateOfMail(true);
    } else {
      setStateOfMail(false);
    }
  };
  return (
    <section className="footer">
      <div className="regmail">
        <p className="">
          Register your email and get exclusive updates about landed property
          prices and trading strategies
        </p>
        <input
          onChange={async (e) => {
            await setUseremail(e.target.value);
            keepTabs();
          }}
          onFocus={() => setDirty(true)}
          type="email"
          name=""
          id=""
          className="reginput"
          placeholder="Email Address"
        />
        {!stateOfMail && dirty ? (
          <small className="erromail">
            <i>
              <img src={invalid} alt="" className="con" />
            </i>{" "}
            Invalid Email Address
          </small>
        ) : (
          ""
        )}
        <br />
        <button className="regbtn">Subscribe</button>
      </div>
      <div className="footernav">
        <Link to={`/${pref}`} className="navlinkF">
          <img src={arrowleft} alt="" />
        </Link>
        <Link to={`/${nxt}`} className="navlinkF">
          <img src={arrowright} alt="" aria-disabled="true" />
        </Link>
      </div>
      <div className="realfooter">
        <div className="">
          Email{" "}
          <div>
            <img src={mailicon} alt="" /> oluokun.devon@gmail.com
          </div>
        </div>
        <div className="socialMediacon">
          <h3>Social Media</h3>
          <div>
            <Link
              to="https://www.instagram.com/dilighthero?igsh=MWhqdGg4MzJ6eXcxaw=="
              className="socialMedia"
            >
              <img src={instagram} alt="" />
            </Link>
            <Link
              to="https://x.com/dilighthero?t=_zk_Ugx5n0rEewEVDkvj7w&s=09"
              className="socialMedia"
            >
              <img src={x} alt="" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
