import React, { useEffect, useState } from "react";
import "../sass/CSS/nft.css";

const Carousel = ({items}) => {
  
  const [current, setcurrent] = useState(1);

  const nextslide = () => {
    setcurrent((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const interval = setInterval(nextslide, 5000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div
      id="carouselContainers"
      style={{
        width: `${items.length * 100}%`,
        transform: `translateX(-${current * (100 / items.length)}%)`, overflow: `hidden`,
        transition: `transform 0.5s`,
      }}
    >
      {items.map((item, index) => (
        <img
          src={item}
          alt=""
          id="carouselimg"
          style={{ width: `${100 / items.length}%` }}
        />
      ))}
    </div>
  );
};

export default Carousel;
