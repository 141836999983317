import React from "react";

import crp1 from "../res/img/imgasset/4136942 1.svg";
import crp2 from "../res/img/imgasset/Jan-Business_report_1 1.svg";
import crp3 from "../res/img/imgasset/6245304 1.svg";
import crp4 from "../res/img/imgasset/7056554 1.svg";

import img1 from "../res/img/imgasset/Carousels 3 1.svg";
import img2 from "../res/img/imgasset/Carousel 1.svg";
import img3 from "../res/img/imgasset/Carousels 2.svg";
import Carousel from "../components/Carousel";
import Footer from "../components/Footer";

const Forex = () => {
  const items = [img1, img2, img3];
  return (
    <section>
      <section className="crobody">
        <h2 className="header-sub">
          Forex Trading <br /> with DEVON
        </h2>
        <div className="carosection">
          <Carousel items={items} />
        </div>
        <div className="dodocontain">
          <p className="saydodo">
            I’m a passionate forex and deriv indices trader with a knack for
            spotting profitable opportunities in the market. With a solid
            foundation in technical and fundamental analysis.
          </p>

          <img src={crp1} alt="" className="dodimg" />
        </div>
        <div className="dodocontain do2">
          <img src={crp2} alt="" className="dodimg" />

          <p className="saydodo">
            I've honed my skills to navigate the ever-changing world of forex
            trading. I've had my fair share of successful trades and have
            developed a disciplined approach to risk management.
          </p>
        </div>
        <div className="dodocontain">
          <p className="saydodo">
            Whether it's day trading, swing trading, or long-term investing, I
            adapt my strategies to suit my goals. Continuous learning is key for
            me, as I'm always seeking to expand my knowledge and stay ahead of
            the game.
          </p>
          <img src={crp3} alt="" className="dodimg" />
        </div>
        <div className="dodocontain do2">
          <img src={crp4} alt="" className="dodimg" />

          <p className="saydodo">
            I'm also dedicated to sharing my insights and helping others succeed
            in their trading journeys. Let's ride the waves of the forex market
            together and make some impressive gains!
          </p>
        </div>
      </section>
      <Footer nxt='nft' pref='cryptotrading'/>
    </section>
  );
};

export default Forex;
