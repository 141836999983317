import React from "react";
import "./home.css";
import proPic from "../res/IMG-20240123-WA0006.jpg";
import Service from "../components/Service";
import Stars from "../components/Stars";

import vector1 from "../res/img/imgasset/mdi_house.svg";
import vector2 from "../res/img/imgasset/infos (2).svg";
import vector3 from "../res/img/imgasset/Vector (1).svg";
import vector4 from "../res/img/imgasset/Vector (2).svg";

import { Link } from "react-router-dom";
const Home = () => {
  return (
    <>
      <div className="body">
        <section className="hero">
          <div className="basicinfo">
            <div className="introname">
              <span className="dd">I</span> <span className="dd">am</span>{" "}
              <br /> <span className="dd">DEVON</span>{" "}
              <span className="dd">OLUOKUN</span>
            </div>
            <div className="heropicbg2">
              <img src={proPic} alt="" />
            </div>
            <button className="goAbout">About Devon</button>
            <div className="about">
              I am a passionate cryptocurrency trading expert, forex trading
              expert, <br />
              I am also a well-versed in NFT creation and also a real estate
              manager.
              <br /> <br />
              I am a dedicated entrpreneur and a client-friendly worker, who
              loves to
              <br /> collaborate with other people in different fields and bring
              their ideals <br />
              and initialtives to reality.
            </div>
          </div>
          <div className="heropicbg">
            <img src={proPic} alt="" />
          </div>
        </section>
        <section className="skillsdetail">
          <div className="rated">
            <small>
              Skills <hr className="decoHr" />
            </small>
            <ul>
              <li>
                <strong>Communication</strong>
                <Stars rate={3} />
              </li>
              <li>
                <strong>Leadership</strong>
                <Stars rate={3} />
              </li>
              <li>
                <strong>Job Execution</strong>
                <Stars rate={4} />
              </li>
              <li>
                <strong>Trading</strong>
                <Stars rate={3} />
              </li>
              <li>
                <strong>Art &amp; Design</strong>
                <Stars rate={2} />
              </li>
            </ul>
            <small>
              Hobbies <hr className="decoHr" />
            </small>
            <p className="hobb">
              Playing chess, watching movies, reading novels or comic
            </p>
          </div>
          <div className="services">
            <small className="servicesmall">
              Check out <br /> How I can serve you
            </small>
            <div className="slinks">
              <Service
                header="Forex Trading"
                sub1="Forex, Deriv Indices,"
                sub2="Risk Management"
                icon={vector3}
                to="forex"
              />
              <Service
                header="Crypto Trading"
                sub1="Check out my trading"
                sub2="strategies and testimonies"
                icon={vector4}
                to="Cryptotrading"
              />
              <Service
                header="NFTs Creation"
                sub1="Check out my NFT"
                sub2="collections  and project collabs"
                icon={vector2}
                to="nft"
              />
              <Service
                header="Real Estate"
                sub1="Dive into the world of,"
                sub2="owning land assets."
                icon={vector1}
              />
            </div>
          </div>
          <div className="slinksmobile">
            <Link className="navbtn" to="/Cryptotrading">
              Start here
            </Link>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;
