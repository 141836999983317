import React from "react";
import "../sass/CSS/nft.css";
import img1 from "../res/img/imgasset/fun-backpacker-german-shepherd-dog-cartoon-character.png";
import whatsapp from "../res/img/imgasset/linkedin_black_logo_icon_147114.svg";
import nftimg from "../res/img/imgasset/real estate icon.svg";
import Footer from "../components/Footer";
import Carousel from "../components/Carousel2";

import imgs from "../res/img/imgasset/febafe13-64b6-4078-80b6-053f423c053f.svg";
import img2 from "../res/img/imgasset/e6d2ab31-2960-420e-8503-7dcff30794c9.svg";
import img3 from "../res/img/imgasset/dfbdf0da-19bb-4cf9-99ee-4926b9c27552.svg";
import img4 from "../res/img/imgasset/d63dbdc4-0f0f-4b97-86b0-b2382b380ce9.svg";
import img5 from "../res/img/imgasset/26199559-d77f-4af4-a6d0-955a8dabdb6a 1.svg";
const Estate = () => {
  const items = [imgs, img2, img3, img4, img5];

  return (
    <section>
      <div className="crobody">
        <div className="nfthero">
          <div className="nftherodis">
            LAND: <br />
            <span className="espan">
              is the first and foremost <br />
              asset you should acquire. <br />
              Invest in Real Estate with Devon.
            </span>
          </div>
          <div className="nftcaro">
            <Carousel items={items} />
          </div>
        </div>
        <div className="dodocontain do2">
          <img src={nftimg} alt="" className="dodimg" />
          <p className="saydodo">
            I am a seasoned real estate manager with 3 years of experience
            managing and growing real estate portfolios, seeking to help clients
            achieve their real estate goals through sound strategic planning and
            execution.
          </p>
        </div>
      </div>
      <Footer pref="nft" nxt="estate" />
    </section>
  );
};

export default Estate;
