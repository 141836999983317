import React from "react";
import "../sass/CSS/nft.css";
import img1 from "../res/img/imgasset/fun-backpacker-german-shepherd-dog-cartoon-character.png";
import whatsapp from "../res/img/imgasset/linkedin_black_logo_icon_147114.svg";
import nftimg from "../res/img/imgasset/Jan-Business_report_1 1.png";
import Footer from "../components/Footer";
import Carousel from "../components/Carousel2";

import imgs from "../res/img/imgasset/nft 1.svg";
import img2 from "../res/img/imgasset/nft 2.svg";
import img3 from "../res/img/imgasset/nft 3.svg";
import img4 from "../res/img/imgasset/nft 4.svg";
import img5 from "../res/img/imgasset/nft 5.svg";
import img6 from "../res/img/imgasset/nft 6.svg";
import img7 from "../res/img/imgasset/nft 7.svg";

const Nft = () => {
  const items = [imgs, img2, img3, img4, img5, img6, img7];


  const whatsappurl = `https://www.linkedin.com/in/devon-oluokun-aaa537278?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app`;

  const whatsappme = () => window.open(whatsappurl, "_blank");

  return (
    <section>
      <div className="crobody">
        <div className="nfthero">
          <div className="nftherodis">
            DEVON: <br />
            Unleashing Creativity <br />
            as a NFT Creator <br />
            <button className="zapdevon" onClick={whatsappme}>
              Contact Devon{" "}
              <img className="contacticon" src={whatsapp} alt="" />
            </button>
          </div>
          <div className="nftcaro">
            <Carousel items={items} />
          </div>
        </div>
        <div className="dodocontain do2">
          <img src={nftimg} alt="" className="dodimg" />
          <p className="saydodo">
            I'm into professional NFT creation. I have been an NFT creator for 4
            years and enthusiast in this recent months, my experience in NFTs
            has being fascinating so far. <br /> My goal is to improve the NFT
            community through gaming, collectibles, most especially in digital
            art exploration and creation of NFT arts
          </p>
        </div>
      </div>
      <Footer pref="forex" nxt="estate" />
    </section>
  );
};

export default Nft;
