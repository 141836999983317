import React, { useEffect, useState } from "react";
import "../sass/CSS/nft.css";

const Carousel = ({ items }) => {
  const [current, setcurrent] = useState(0);

  const nextslide = () => {
    setcurrent((prevIndex) => (prevIndex + 1) % items.length);
  };

  useEffect(() => {
    const interval = setInterval(nextslide, 3000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div id="carousel">
      {items.map((item, index) => (
        <img
        key={index}
          src={item}
          alt=""
          // id="carouselimg"
          className={`carousel-items ${index == current ? 'active':''}`}
        />
      ))}
    </div>
  );
};

export default Carousel;
