import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pagies/Home";
import Cryptotrading from "./pagies/Cryptotrading";
import Nft from "./pagies/Nft";
import Forex from "./pagies/Forex";
import Estate from "./pagies/Estate";

function App() {
  return (
    <>
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="Cryptotrading" element={<Cryptotrading />} />
        <Route path="nft" element={<Nft />} />
        <Route path="forex" element={<Forex />} />
        <Route path="estate" element={<Estate />} />
      </Routes>
    </>
  );
}

export default App;
